import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import type {
  BabyChecklistCategoryView,
  BabyChecklistView,
} from '~/components/BabyNav/ChecklistDrawer/types';

import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import TagV2, { TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import { mapChecklistWithCompletedSubcategory } from '~/components/BabyNav/ChecklistDrawer/utils';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { updateBabyChecklistSubcategory } from '~/client/v1/babyChecklist';

import sharedStyles from './checklistDrawer.module.less';
import styles from './checklistDrawerCategory.module.less';

type ChecklistDrawerCategoryProps = {
  checklist: BabyChecklistView;
  currentChecklistCategory: BabyChecklistCategoryView;
  openEditCategoryDrawerLayer: () => void;
  setChecklist: (checklist: BabyChecklistView) => void;
};

/**
 * Baby checklist drawer layer that shows a single category
 */
export const ChecklistDrawerCategory = ({
  checklist,
  currentChecklistCategory,
  openEditCategoryDrawerLayer,
  setChecklist,
}: ChecklistDrawerCategoryProps): JSX.Element => {
  const dispatch = useDispatch();

  const { subcategories } = currentChecklistCategory;

  const handleUpdateBabyChecklistSubcategory = (subcategoryUuid?: string, completed?: boolean) => {
    const originalChecklist = { ...checklist };

    // Immediate client-side update of subcategory completed status
    setChecklist(
      mapChecklistWithCompletedSubcategory({
        checklist,
        updatedCategoryUuid: currentChecklistCategory.uuid || '',
        updatedSubcategoryCompleted: Boolean(completed),
        updatedSubcategoryUuid: subcategoryUuid || '',
      })
    );

    // API request to update subcategory completed status
    updateBabyChecklistSubcategory({
      checklistUuid: checklist.uuid || '',
      subcategoryUuid: subcategoryUuid || '',
      subcategoryUpdates: {
        completed,
      },
    }).catch(() => {
      dispatch(
        toastsActions.negative({
          headline: `Something went wrong—please try again later`,
        })
      );
      setChecklist(originalChecklist);
    });
  };

  return subcategories?.length && subcategories.length > 0 ? (
    <div>
      <div className={sharedStyles.subtitle}>
        <P.BodyBase color="tertiary">
          {currentChecklistCategory.completedCount}/{currentChecklistCategory.totalCount}
        </P.BodyBase>
        <LinkV2 onClick={openEditCategoryDrawerLayer} role="button" variant="secondary">
          Edit
        </LinkV2>
      </div>
      <ul>
        {subcategories.map(
          (subcategory, index) =>
            !subcategory.hidden && (
              <li className={cx(sharedStyles.drawerListItem, styles.subcategory)} key={index}>
                <LinkV2
                  aria-label={subcategory.label}
                  className={cx(sharedStyles.drawerItem, sharedStyles.drawerItemHover)}
                  href={`/shop/category/${subcategory.taxonomy_node_key || ''}`}
                  noUnderline
                  subtle
                >
                  <div
                    className={cx(styles.subcategorySection, {
                      [styles.subcategoryCompleted]: subcategory.completed,
                    })}
                  >
                    <CheckboxField
                      checked={subcategory.completed}
                      id={subcategory.taxonomy_node_key || ''}
                      onChange={() =>
                        handleUpdateBabyChecklistSubcategory(
                          subcategory.uuid,
                          !subcategory.completed
                        )
                      }
                    />
                    {subcategory.label}
                  </div>
                  <div className={styles.subcategorySection}>
                    {subcategory.essential ? (
                      <TagV2 size={TagV2Size.SMALL} variant={TagV2Variant.NEON_LIME}>
                        Essential
                      </TagV2>
                    ) : null}
                    <div className={styles.addGift}>Add gift</div>
                  </div>
                </LinkV2>
              </li>
            )
        )}
      </ul>
    </div>
  ) : (
    <></>
  );
};
