import React from 'react';
import cx from 'classnames';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { getAccountSettingNavForStore } from '@zola-helpers/client/dist/es/util/accountSettingsNav';

import { ArrowNarrowLeftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ArrowNarrowLeft';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';

import sharedStyles from '../sharedStyles.module.less';
import styles from './mobileVerticalNavAccount.module.less';

type MobileVerticalNavAccountProps = {
  isVerticalNavAccountOpen?: boolean;
  openVerticalNav: (open: boolean) => void;
  openVerticalNavAccount: (open: boolean) => void;
  userContext?: UserContext;
};

export const MobileVerticalNavAccount = ({
  isVerticalNavAccountOpen,
  openVerticalNav,
  openVerticalNavAccount,
  userContext,
}: MobileVerticalNavAccountProps): JSX.Element => {
  const accountNavData = getAccountSettingNavForStore(userContext || {}, 'BABY_REGISTRY');

  return (
    <div
      className={cx(sharedStyles.verticalNav, {
        [sharedStyles.verticalNavOpen]: isVerticalNavAccountOpen === true,
        [sharedStyles.verticalNavClose]: isVerticalNavAccountOpen === false,
      })}
    >
      <div
        className={cx(sharedStyles.verticalNavInner, {
          [sharedStyles.verticalNavInnerOpen]: isVerticalNavAccountOpen === true,
          [sharedStyles.verticalNavInnerClose]: isVerticalNavAccountOpen === false,
        })}
      >
        <div className={sharedStyles.center}>
          <div className={styles.title}>
            <LinkV2
              className={cx(sharedStyles.link, styles.back)}
              noUnderline
              onClick={() => openVerticalNavAccount(false)}
              role="button"
              subtle
            >
              <ArrowNarrowLeftIcon title="" height={20} width={20} />
            </LinkV2>
            <P.BodyLarge>Account settings</P.BodyLarge>
          </div>
          <LinkV2
            aria-label="Close menu"
            role="button"
            onClick={() => {
              openVerticalNav(false);
              openVerticalNavAccount(false);
            }}
          >
            <XIcon height={20} title="" width={20} />
          </LinkV2>
        </div>
        <div>
          {accountNavData.map((section, sectionIndex) => (
            <div className={styles.accountSection} key={sectionIndex}>
              <div className={styles.accountHeading}>{section.heading}</div>
              {section.links.map(({ href, text }, linkIndex) => (
                <LinkV2
                  className={cx(sharedStyles.link, styles.accountLink)}
                  href={href}
                  key={linkIndex}
                  noUnderline
                  sizes="small"
                  subtle
                  target="_self"
                >
                  {text}
                </LinkV2>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
