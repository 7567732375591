import React from 'react';
import cx from 'classnames';

import {
  PrimaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { PrimaryNav } from '~/components/BabyNav/PrimaryNav/PrimaryNav';
import { SecondaryNav } from '~/components/BabyNav/SecondaryNav/SecondaryNav';

import { useBabyNavContext } from '~/contexts/BabyNavContext';
import styles from './desktopNav.module.less';

type DesktopNavProps = {
  hideContentOnScrollDown: boolean;
  primaryLinkId?: PrimaryNavIdentifier | '';
  secondaryLinkId: string;
  secondaryNavData: SecondaryNavItem[];
  userContext?: UserContext;
};

export const DesktopNav = ({
  hideContentOnScrollDown,
  primaryLinkId,
  secondaryLinkId,
  secondaryNavData,
  userContext,
}: DesktopNavProps): JSX.Element => {
  const { legacy } = useBabyNavContext();

  return (
    <div className={cx(styles.desktopNav, { [styles.desktopNavNew]: !legacy })}>
      <PrimaryNav
        hideContentOnScrollDown={hideContentOnScrollDown}
        primaryLinkId={primaryLinkId}
        userContext={userContext}
      />
      {secondaryNavData.length > 0 && !legacy && (
        <SecondaryNav
          hideContentOnScrollDown={hideContentOnScrollDown}
          secondaryLinkId={secondaryLinkId}
          secondaryNavData={secondaryNavData}
        />
      )}
    </div>
  );
};
