import React from 'react';
import cx from 'classnames';

import { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';

import { NavMapper } from '~/components/navV2/NavMapper/NavMapper';

import sharedStyles from '../sharedStyles.module.less';
import styles from './secondaryNav.module.less';

type SecondaryNavProps = {
  hideContentOnScrollDown: boolean;
  secondaryNavData: SecondaryNavItem[];
  secondaryLinkId: string;
};

export const SecondaryNav = ({
  hideContentOnScrollDown,
  secondaryLinkId,
  secondaryNavData,
}: SecondaryNavProps): JSX.Element => {
  return (
    <div className={sharedStyles.scrimContainer}>
      <div className={styles.secondaryNav}>
        <div
          className={cx(styles.secondaryNavInner, {
            [styles.fullWidth]: hideContentOnScrollDown,
          })}
        >
          <NavMapper activeLinkId={secondaryLinkId} navData={secondaryNavData} type="Secondary" />
        </div>
      </div>
      <div className={sharedStyles.leftScrim} />
      <div className={sharedStyles.rightScrim} />
    </div>
  );
};
