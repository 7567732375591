import React, { useMemo } from 'react';
import cx from 'classnames';

import type {
  BabyChecklistCategoryView,
  BabyChecklistView,
} from '~/components/BabyNav/ChecklistDrawer/types';

import { CheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Check';
import { ChevronRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronRight';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import sharedStyles from './checklistDrawer.module.less';
import styles from './checklistDrawerAll.module.less';

type ChecklistDrawerAllProps = {
  checklist: BabyChecklistView;
  openCategoryDrawerLayer: (category: BabyChecklistCategoryView, categoryIndex: number) => void;
};

/**
 * Baby checklist drawer layer that shows all categories
 */
export const ChecklistDrawerAll = ({
  checklist,
  openCategoryDrawerLayer,
}: ChecklistDrawerAllProps): JSX.Element => {
  const { categories } = checklist;

  const checklistCompletedPercent = useMemo(() => {
    const checklistTotalCount =
      categories?.reduce((total, category) => total + category.totalCount, 0) || 0;
    const checklistCompletedCount =
      categories?.reduce((total, category) => total + category.completedCount, 0) || 0;
    return Math.round((checklistCompletedCount / checklistTotalCount) * 100);
  }, [categories]);

  return categories?.length && categories.length > 0 ? (
    <div>
      <div className={sharedStyles.subtitle}>
        <P.BodyBase color="tertiary">{checklistCompletedPercent}%</P.BodyBase>
      </div>
      <ul>
        {categories.map((category, categoryIndex) => {
          const isComplete = category.completedCount === category.totalCount;
          return (
            <li className={sharedStyles.drawerListItem} key={categoryIndex}>
              <LinkV2
                aria-label={category.label}
                className={cx(
                  sharedStyles.drawerItem,
                  sharedStyles.drawerItemHover,
                  styles.category
                )}
                noUnderline
                onClick={() => openCategoryDrawerLayer(category, categoryIndex)}
                role="button"
                subtle
              >
                <div
                  className={cx(styles.categoryImage, { [styles.categoryComplete]: isComplete })}
                >
                  {isComplete && (
                    <div className={styles.categoryCompleteCircle}>
                      <CheckIcon
                        className={styles.categoryCompleteIcon}
                        height={16}
                        title=""
                        width={16}
                      />
                    </div>
                  )}
                  <img alt="" src={category.image_url} />
                </div>
                <div className={styles.categoryContent}>
                  <div className={styles.categoryText}>
                    <div>{category.label}</div>
                    <div className={styles.categoryCount}>
                      {category.completedCount}/{category.totalCount}
                    </div>
                  </div>
                  <div className={styles.categoryProgressBar}>
                    <div
                      className={styles.categoryProgress}
                      style={{
                        width:
                          category.completedCount > 0
                            ? `${(category.completedCount / category.totalCount) * 100}%`
                            : '8px',
                      }}
                    />
                  </div>
                </div>
                <ChevronRightIcon height={24} title="" width={24} />
              </LinkV2>
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <></>
  );
};
