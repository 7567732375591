import React, { createContext, useContext } from 'react';

type BabyNavContextValue = {
  legacy: boolean;
};

export const BabyNavContext = createContext<BabyNavContextValue>({ legacy: false });

export const useBabyNavContext = () => useContext(BabyNavContext);

export const BabyNavContextProvider: React.FC<{ legacy: boolean }> = ({ legacy, children }) => {
  return <BabyNavContext.Provider value={{ legacy }}>{children}</BabyNavContext.Provider>;
};
