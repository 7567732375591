import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import type {
  BabyChecklistCategoryView,
  BabyChecklistView,
} from '~/components/BabyNav/ChecklistDrawer/types';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import type { WBabyChecklistSubcategoryView } from '@zola/svc-web-api-ts-client';

import { ChecklistDrawerAll } from '~/components/BabyNav/ChecklistDrawer/ChecklistDrawerAll';
import { ChecklistDrawerCategory } from '~/components/BabyNav/ChecklistDrawer/ChecklistDrawerCategory';
import { ChecklistDrawerCategoryEdit } from '~/components/BabyNav/ChecklistDrawer/ChecklistDrawerCategoryEdit';
import SideDrawerV2 from '@zola/zola-ui/src/components/SideDrawerV2';

import { getBabyChecklist, updateBabyChecklistSubcategory } from '~/client/v1/babyChecklist';
import {
  mapChecklist,
  mapChecklistWithHiddenSubcategories,
} from '~/components/BabyNav/ChecklistDrawer/utils';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

import styles from './checklistDrawer.module.less';

const toKebabCase = (str?: string) => str?.replace(/\s+/g, '-')?.toLowerCase();

type ChecklistDrawerProps = {
  activeTaxonomyNodeKey: string;
  userContext: UserContext;
};

/**
 * Baby checklist drawer wrapper responsible for fetching data and managing drawer layers.
 * There are two ways to open the drawer:
 *   1. Listen for custom event `OPEN_BABY_CHECKLIST_DRAWER` from anywhere, e.g. module click
 *     - If active taxonomy node key on nav matches up with a checklist category, opens category layer
 *     - Otherwise, opens all categories layer
 *   2. Watch for query param `babyChecklist` on page load
 *     - `?babyChecklist=all` opens all categories layer
 *     - `?babyChecklist=${category.taxonomy_node_key}` with 1:1 taxonomy node matchup opens category layer
 *     - `?babyChecklist=${toKebabCase(category.label)}` without 1:1 taxonomy node matchup also opens category layer
 *     - `?babyChecklist=${invalid}` that doesn't match up with any checklist category falls back to all categories layer
 */
export const ChecklistDrawer = ({
  activeTaxonomyNodeKey,
  userContext,
}: ChecklistDrawerProps): JSX.Element => {
  const dispatch = useDispatch();

  const [checklist, setChecklist] = useState<BabyChecklistView>({});
  const [currentChecklistCategoryIndex, setCurrentChecklistCategoryIndex] = useState(0);
  const currentChecklistCategory: BabyChecklistCategoryView = useMemo(
    () =>
      checklist?.categories?.[currentChecklistCategoryIndex] || {
        completedCount: 0,
        totalCount: 0,
      },
    [checklist?.categories, currentChecklistCategoryIndex]
  );
  const [checklistSubcategoriesToUpdate, setChecklistSubcategoriesToUpdate] = useState<
    WBabyChecklistSubcategoryView[]
  >([]);

  // Drawer layer 0: closed, 1: ChecklistDrawerAll, 2: ChecklistDrawerCategory, 3: ChecklistDrawerCategoryEdit
  const [drawerLayer, setDrawerLayer] = useState(0);

  const drawerTitle = useMemo(
    () => (drawerLayer === 1 ? 'Your checklist' : currentChecklistCategory.label),
    [drawerLayer, currentChecklistCategory.label]
  );

  // Updates URL to keep track of current state of drawer without reloading the page
  const updateQueryParam = (value?: string) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (value) {
      searchParams.set('babyChecklist', value);
    } else {
      searchParams.delete('babyChecklist');
    }

    const newUrl = searchParams.toString()
      ? `${window.location.pathname}?${searchParams.toString()}`
      : window.location.pathname;

    window.history.pushState({}, '', newUrl);
  };

  const openDrawer = useCallback(() => {
    if (userContext.has_baby_registry) {
      getBabyChecklist()
        .then((response) => {
          const mappedChecklist = mapChecklist(response);
          setChecklist(mappedChecklist);

          // Open category layer if query param or active taxonomy node key on nav matches
          // up with a checklist category; otherwise, open the all categories layer
          const params = Object.fromEntries(new URLSearchParams(window.location.search));
          const categoryIndex = mappedChecklist.categories?.findIndex(
            (category) =>
              (params.babyChecklist &&
                (category.taxonomy_node_key === params.babyChecklist ||
                  toKebabCase(category.label) === params.babyChecklist)) ||
              (activeTaxonomyNodeKey && category.taxonomy_node_key === activeTaxonomyNodeKey)
          );
          if (typeof categoryIndex === 'number' && categoryIndex >= 0) {
            setDrawerLayer(2);
            setCurrentChecklistCategoryIndex(categoryIndex);
            if (activeTaxonomyNodeKey) {
              updateQueryParam(activeTaxonomyNodeKey);
            }
          } else {
            setDrawerLayer(1);
            updateQueryParam('all');
          }
        })
        .catch(() => null);
    }
  }, [activeTaxonomyNodeKey, userContext.has_baby_registry]);

  const openCategoryDrawerLayer = (category: BabyChecklistCategoryView, categoryIndex: number) => {
    setDrawerLayer(2);
    setCurrentChecklistCategoryIndex(categoryIndex);
    updateQueryParam(category.taxonomy_node_key || toKebabCase(category.label));
  };

  const openEditCategoryDrawerLayer = () => {
    setDrawerLayer(3);
  };

  const closeDrawer = useCallback(
    (closeAll) => {
      if (closeAll || drawerLayer === 1) {
        setDrawerLayer(0);
        setCurrentChecklistCategoryIndex(0);
        updateQueryParam();
        window.dispatchEvent(new CustomEvent('CLOSE_BABY_CHECKLIST_DRAWER'));
      } else {
        setDrawerLayer(drawerLayer - 1);
        if (drawerLayer === 2) {
          updateQueryParam('all');
        }
      }
    },
    [drawerLayer]
  );

  const saveEditCategoryDrawerChanges = useCallback(async () => {
    const updateSubcategoryPromises = checklistSubcategoriesToUpdate.map((subcategory) => {
      return updateBabyChecklistSubcategory({
        checklistUuid: checklist.uuid || '',
        subcategoryUuid: subcategory.uuid || '',
        subcategoryUpdates: {
          hidden: !subcategory.hidden,
        },
      });
    });

    await Promise.all(updateSubcategoryPromises)
      .then(() => {
        setChecklist(
          mapChecklistWithHiddenSubcategories({
            checklist,
            checklistSubcategoriesToUpdate,
            updatedCategoryUuid: currentChecklistCategory.uuid || '',
          })
        );
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({
            headline: `Something went wrong—please try again later`,
          })
        );
        closeDrawer(true);
      });
  }, [
    checklist,
    checklistSubcategoriesToUpdate,
    closeDrawer,
    currentChecklistCategory.uuid,
    dispatch,
  ]);

  // Entry point to baby checklist drawer - listens for custom event from anywhere, e.g. module click
  useEffect(() => {
    window.addEventListener('OPEN_BABY_CHECKLIST_DRAWER', openDrawer);
    return () => window.removeEventListener('OPEN_BABY_CHECKLIST_DRAWER', openDrawer);
  }, [openDrawer]);

  // Entry point to baby checklist drawer - watches out for query param on page load
  useEffectOnce(() => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    if (params.babyChecklist) {
      openDrawer();
    }
  });

  return (
    <SideDrawerV2
      confirmOnClose={drawerLayer === 3 && checklistSubcategoriesToUpdate.length > 0}
      isOpen={drawerLayer > 0}
      onClose={closeDrawer}
      onSave={drawerLayer === 3 ? saveEditCategoryDrawerChanges : undefined}
      showCancelButton={drawerLayer === 3}
      title={drawerTitle}
      useBackButton={drawerLayer > 1}
      useCloseButton
    >
      <div className={styles.checklistDrawer}>
        {drawerLayer === 1 && (
          <ChecklistDrawerAll
            checklist={checklist}
            openCategoryDrawerLayer={openCategoryDrawerLayer}
          />
        )}
        {drawerLayer === 2 && (
          <ChecklistDrawerCategory
            checklist={checklist}
            currentChecklistCategory={currentChecklistCategory}
            openEditCategoryDrawerLayer={openEditCategoryDrawerLayer}
            setChecklist={setChecklist}
          />
        )}
        {drawerLayer === 3 && (
          <ChecklistDrawerCategoryEdit
            checklistSubcategoriesToUpdate={checklistSubcategoriesToUpdate}
            currentChecklistCategory={currentChecklistCategory}
            setChecklistSubcategoriesToUpdate={setChecklistSubcategoriesToUpdate}
          />
        )}
      </div>
    </SideDrawerV2>
  );
};
