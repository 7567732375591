import React from 'react';
import cx from 'classnames';

import CartContainer from '~/components/nav/TopNav/CartContainer';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { Menu2Icon } from '@zola/zola-ui/src/components/SvgIconsV3/Menu2';
import ZolaBabyLogo from '@zola/zola-ui/src/components/ZolaBabyLogo';

import styles from './mobilePrimaryNav.module.less';

type MobilePrimaryNavProps = {
  hideContentOnScrollDown: boolean;
  openVerticalNav: (open: boolean) => void;
};

export const MobilePrimaryNav = ({
  hideContentOnScrollDown,
  openVerticalNav,
}: MobilePrimaryNavProps): JSX.Element => {
  return (
    <div
      className={cx(styles.mobilePrimaryNav, {
        [styles.hideContentOnScroll]: hideContentOnScrollDown,
      })}
    >
      <LinkV2
        aria-label="Open menu"
        className={styles.menuButton}
        role="button"
        onClick={() => openVerticalNav(true)}
      >
        <Menu2Icon height={20} title="" width={20} />
      </LinkV2>
      <a aria-label="Zola Baby homepage" href="/">
        <ZolaBabyLogo />
      </a>
      <CartContainer showBasketIcon />
    </div>
  );
};
