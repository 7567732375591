import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  WBabyChecklistSubcategoryView,
  WBabyChecklistView,
  WBabyUpdateChecklistSubcategoryRequest,
} from '@zola/svc-web-api-ts-client';

export const getBabyChecklist = (): Promise<WBabyChecklistView> => {
  return ApiService.get('/website-nav/web-api/v1/babyChecklist/getChecklist');
};

export const updateBabyChecklistSubcategory = ({
  checklistUuid,
  subcategoryUuid,
  subcategoryUpdates,
}: {
  checklistUuid: string;
  subcategoryUuid: string;
  subcategoryUpdates: WBabyUpdateChecklistSubcategoryRequest;
}): Promise<WBabyChecklistSubcategoryView> => {
  return ApiService.put(
    `/website-nav/web-api/v1/babyChecklist/${checklistUuid}/${subcategoryUuid}/updateChecklist`,
    subcategoryUpdates
  );
};
