import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import type { WTopNavMessageView } from '@zola/svc-web-api-ts-client';

import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import TagV2 from '@zola/zola-ui/src/components/Tag/TagV2';

import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { showModal } from '~/actions/ModalActions';
import { getTopNavDiscountTerms } from '~/client/v1/topNavMessages';

import styles from './banner.module.less';

type BannerProps = {
  banner?: WTopNavMessageView;
  showBannerAnimation: boolean;
};

export const Banner = ({ banner, showBannerAnimation }: BannerProps): JSX.Element => {
  const {
    message,
    link_text: cta,
    link: ctaLink,
    label,
    promotion_code: promotionCode,
    id,
  } = banner || {};

  const dispatch = useDispatch();

  const getPromoTermsAndShowModal = (e: React.MouseEvent) => {
    e.preventDefault();

    if (id) {
      getTopNavDiscountTerms(id)
        .then((response) => {
          if (response) {
            dispatch(
              showModal(
                'PROMO_TERMS_MODAL',
                { termsAndConditions: response.terms_and_conditions, title: response.label },
                { size: 'sm' }
              )
            );
          }
        })
        .catch(() =>
          dispatch(
            toastsActions.negative({
              headline: 'Unable to load terms. Please try again.',
            })
          )
        );
    }
  };

  const BannerWrapper = ctaLink ? 'a' : 'div';
  const bannerWrapperProps = ctaLink ? { href: ctaLink } : {};

  return banner ? (
    <BannerWrapper
      className={cx(styles.bannerWrapper, { [styles.showAnimation]: showBannerAnimation })}
      {...bannerWrapperProps}
    >
      {label && <TagV2 className={styles.tag}>{label}</TagV2>}
      {message && (
        <P.BodySmall className={styles.tanColor} dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {promotionCode && id && (
        <LinkV2
          className={cx(styles.terms, styles.tanColor)}
          noUnderline
          onClick={getPromoTermsAndShowModal}
          role="button"
          sizes="extraSmall"
          subtle
        >
          Terms
        </LinkV2>
      )}
      {cta && ctaLink && (
        <P.BodySmall className={cx(styles.cta, styles.tanColor)}>{cta}</P.BodySmall>
      )}
    </BannerWrapper>
  ) : (
    <></>
  );
};
