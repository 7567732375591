import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';

import UnderlinePostAuth1 from '~/assets/images/baby/underline-post-auth-1.svg';
import UnderlinePostAuth2 from '~/assets/images/baby/underline-post-auth-2.svg';
import UnderlinePostAuth3 from '~/assets/images/baby/underline-post-auth-3.svg';
import UnderlinePreAuth1 from '~/assets/images/baby/underline-pre-auth-1.svg';
import UnderlinePreAuth2 from '~/assets/images/baby/underline-pre-auth-2.svg';
import UnderlinePreAuth3 from '~/assets/images/baby/underline-pre-auth-3.svg';

export const babyPreAuthPrimaryNavData = [
  {
    id: PrimaryNavIdentifier.REGISTRY,
    path: '/',
    title: 'Baby registry',
    underlineAsset: UnderlinePreAuth1,
  },
  {
    id: PrimaryNavIdentifier.SHOP,
    path: '/shop',
    title: 'Shop',
    underlineAsset: UnderlinePreAuth2,
  },
  {
    id: PrimaryNavIdentifier.FIND_A_REGISTRY,
    path: '/search/baby-registry',
    title: 'Find a registry',
    underlineAsset: UnderlinePreAuth3,
  },
];

export const babyPostAuthPrimaryNavData = (userContext?: UserContext) => [
  {
    id: PrimaryNavIdentifier.REGISTRY,
    path: '/my-registry',
    title: 'Your registry',
    underlineAsset: UnderlinePostAuth1,
  },
  {
    id: PrimaryNavIdentifier.SHOP,
    path: '/registry/add-gifts',
    title: 'Add gifts',
    underlineAsset: UnderlinePostAuth2,
  },
  ...insertInArrayIf(Boolean(userContext?.has_baby_registry), {
    id: PrimaryNavIdentifier.CHECKLIST,
    path: '?babyChecklist=all',
    title: 'Checklist',
    underlineAsset: UnderlinePostAuth2,
  }),
  {
    id: PrimaryNavIdentifier.GIFT_TRACKER,
    path: '/registry/gift-tracker',
    title: 'Gift tracker',
    underlineAsset: UnderlinePostAuth3,
  },
];

export const babyAccountNavData = [
  {
    label: 'Your account',
    value: '/account/settings/info',
  },
  {
    label: 'Registry settings',
    value: '/account/settings/details',
  },
  {
    label: "Orders you've placed",
    value: '/account/settings/orders',
  },
  {
    label: 'Ratings and reviews',
    value: '/account/settings/reviews',
  },
  {
    label: 'Zola store credit',
    value: '/account/settings/credits',
  },
  {
    label: 'Privacy settings',
    value: '/account/settings/privacy',
  },
  {
    label: 'Switch registries',
    value: '/account/settings/registries',
  },
  {
    label: 'Log out',
    value: '/',
  },
];
