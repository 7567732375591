import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/reducers/index';

import NAV_DATA, {
  PrimaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { AccountDropdown } from '~/components/BabyNav/AccountDropdown/AccountDropdown';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import CartContainer from '~/components/nav/TopNav/CartContainer';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { SearchIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Search';
import UniversalSearch from '~/components/search/UniversalSearch';
import ZolaBabyLogo from '@zola/zola-ui/src/components/ZolaBabyLogo';

import {
  babyPreAuthPrimaryNavData,
  babyPostAuthPrimaryNavData,
} from '~/components/BabyNav/babyNavData';
import { showModal } from '~/actions/ModalActions';

import { useBabyNavContext } from '../../../contexts/BabyNavContext';
import styles from './primaryNav.module.less';

const {
  EVENTS: { MODAL_TYPE_AUTH },
} = NAV_DATA;

type PrimaryNavProps = {
  hideContentOnScrollDown: boolean;
  primaryLinkId?: PrimaryNavIdentifier | '';
  userContext?: UserContext;
};

export const PrimaryNav = ({
  hideContentOnScrollDown,
  primaryLinkId,
  userContext,
}: PrimaryNavProps): JSX.Element => {
  const { legacy } = useBabyNavContext();

  const dispatch = useDispatch<AppDispatch>();

  const isGuest = !userContext?.has_baby_registry;
  const [isSearchActive, setIsSearchActive] = useState(false);

  const showAppDownloadModal = () => {
    dispatch(
      showModal('BABY_APP_DOWNLOAD_MODAL', {}, { size: 'sm', ariaLabel: 'Registry not visible' })
    );
  };

  const showLoginModal = () => {
    dispatch(
      showModal(
        MODAL_TYPE_AUTH,
        {
          className: styles.authModal,
          authState: 'login',
          hideVendorLogin: true,
          loginCallback: () => {
            return window.location.assign('/onboard'); // redirects to manage registry if  already a baby user
          },
          signupLinkCallback: () => window.location.assign('/onboard'),
        },
        { size: 'sm', ariaLabel: 'Log in', v2Styles: true }
      )
    );
  };

  const showBabyChecklistDrawer = (e: React.MouseEvent) => {
    e.preventDefault();
    window.dispatchEvent(new CustomEvent('OPEN_BABY_CHECKLIST_DRAWER'));
  };

  const toggleSearchActive = () => {
    setIsSearchActive(!isSearchActive);
  };

  // Pre-auth nav
  if (!legacy && isGuest) {
    return (
      <div className={styles.primaryNav}>
        <div
          className={cx(styles.primaryNavInner, {
            [styles.hideContentOnScroll]: hideContentOnScrollDown,
          })}
        >
          <div className={cx(styles.navSection, styles.navSectionLeft)}>
            {babyPreAuthPrimaryNavData.map((navItem, index) => (
              <LinkV2
                className={cx(styles.link, { [styles.active]: primaryLinkId === navItem.id })}
                href={navItem.path}
                key={index}
                noUnderline
              >
                {navItem.title}
                <img alt="" className={styles.linkUnderline} src={navItem.underlineAsset} />
              </LinkV2>
            ))}
          </div>
          <a aria-label="Zola Baby homepage" href="/">
            <ZolaBabyLogo className={styles.logo} />
          </a>
          <div className={cx(styles.navSection, styles.navSectionRight)}>
            <LinkV2
              aria-label="Search"
              className={cx(styles.iconButton, styles.searchButton)}
              role="button"
              onClick={toggleSearchActive}
            >
              <SearchIcon height={20} title="" width={20} />
            </LinkV2>
            <ButtonV3
              className={cx(styles.searchInput, styles.searchInputPreAuth)}
              onClick={toggleSearchActive}
              variant="transparent"
            >
              <SearchIcon className={styles.searchInputIcon} height={20} title="" width={20} />
              Search...
            </ButtonV3>
            <div className={isSearchActive ? styles.searchActive : styles.searchInactive}>
              {isSearchActive && (
                <UniversalSearch
                  activeLinkId={primaryLinkId}
                  fixedWidth
                  onCloseSearch={toggleSearchActive}
                />
              )}
            </div>
            <CartContainer className={styles.iconButton} showBasketIcon />
            <ButtonV3 onClick={showLoginModal} variant="secondary">
              Log in
            </ButtonV3>
            <ButtonV3 component="a" href="/onboard">
              Get started
            </ButtonV3>
          </div>
        </div>
      </div>
    );
  }

  // Post-auth nav
  if (!legacy) {
    return (
      <div className={styles.primaryNav}>
        <div
          className={cx(styles.primaryNavInner, {
            [styles.hideContentOnScroll]: hideContentOnScrollDown,
          })}
        >
          <div className={cx(styles.navSection, styles.navSectionLeft)}>
            <a aria-label="Zola Baby homepage" href="/">
              <ZolaBabyLogo type="heart" />
            </a>
            {babyPostAuthPrimaryNavData(userContext).map((navItem, index) => {
              return (
                <LinkV2
                  className={cx(styles.link, { [styles.active]: primaryLinkId === navItem.id })}
                  href={navItem.path}
                  key={index}
                  noUnderline
                  {...(navItem.id === PrimaryNavIdentifier.CHECKLIST
                    ? { onClick: showBabyChecklistDrawer }
                    : {})}
                >
                  {navItem.title}
                  <img alt="" className={styles.linkUnderline} src={navItem.underlineAsset} />
                </LinkV2>
              );
            })}
          </div>
          <div className={cx(styles.navSection, styles.navSectionRight)}>
            <ButtonV3
              className={styles.searchInput}
              onClick={toggleSearchActive}
              variant="transparent"
            >
              <SearchIcon className={styles.searchInputIcon} height={20} title="" width={20} />
              Search...
            </ButtonV3>
            <div className={isSearchActive ? styles.searchActive : styles.searchInactive}>
              {isSearchActive && (
                <UniversalSearch
                  activeLinkId={primaryLinkId}
                  fixedWidth
                  onCloseSearch={toggleSearchActive}
                />
              )}
            </div>
            <CartContainer className={styles.iconButton} showBasketIcon />
            <AccountDropdown userContext={userContext} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.oldPrimaryNav, {
        [styles.hideContentOnScroll]: hideContentOnScrollDown,
      })}
    >
      <div className={cx(styles.navSection, styles.navSectionLeft)}>
        <LinkV2
          className={styles.link}
          role="button"
          sizes="smaller"
          noUnderline
          onClick={showAppDownloadModal}
        >
          Download the app
        </LinkV2>
      </div>
      <div className={cx(styles.navSection, styles.navCenter)}>
        <a href="/">
          <ZolaBabyLogo className={styles.logo} />
        </a>
      </div>
      <div className={cx(styles.navSection, styles.navSectionRight)}>
        <CartContainer className={styles.cartContainer} hideIfEmpty isDesktopNavV3 />
        <LinkV2 className={styles.link} href="/search/baby-registry" sizes="smaller" noUnderline>
          Find a registry
        </LinkV2>
        <LinkV2 className={styles.link} href="/faq" sizes="smaller" noUnderline>
          Help
        </LinkV2>
      </div>
    </div>
  );
};
