import React, { useRef } from 'react';
import cx from 'classnames';

import { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import useScrollActiveNavItemToView from '~/components/navV3/PostAuthMobile/useScrollActiveNavItemToView';

import sharedStyles from '../sharedStyles.module.less';
import styles from './mobileSecondaryNav.module.less';

export type MobileSecondaryNavProps = {
  secondaryLinkId: string;
  secondaryNavData: SecondaryNavItem[];
};

export const MobileSecondaryNav = ({
  secondaryLinkId,
  secondaryNavData,
}: MobileSecondaryNavProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  useScrollActiveNavItemToView(containerRef);

  return (
    <div className={sharedStyles.scrimContainer}>
      <div className={styles.mobileSecondaryNav} ref={containerRef}>
        {secondaryNavData.map((node, i) => (
          <LinkV2
            className={cx(styles.mobileSecondaryNavItem, {
              [styles.active]: secondaryLinkId === node.id,
            })}
            href={node.path}
            key={`mobile-secondary-nav-item-${i}`}
            noUnderline
            sizes="smaller"
            subtle
          >
            {node.title}
          </LinkV2>
        ))}
      </div>
      <div className={sharedStyles.leftScrim} />
      <div className={sharedStyles.rightScrim} />
    </div>
  );
};
