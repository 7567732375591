import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import type { WTopNavMessageView } from '@zola/svc-web-api-ts-client';

import { ChevronRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronRight';
import { ChevronLeftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronLeft';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import { BoxIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Box';
import { Discount2Icon } from '@zola/zola-ui/src/components/SvgIconsV3/Discount2';
import { TagMoneyIcon } from '@zola/zola-ui/src/components/SvgIconsV3/TagMoney';
import { Banner } from '~/components/BabyNav/Banner/Banner';

import featureFlags from '~/util/featureFlags';
import { getZolaHref } from '~/util/getZolaHref';
import { isLoggedIn } from '~/util/authHelpers';
import { useBabyNavContext } from '~/contexts/BabyNavContext';
import { TopNavMessageContext, getTopNavMessages } from '~/client/v1/topNavMessages';

import styles from './multiAnnouncementBanner.module.less';

const PERKS = [
  { icon: BoxIcon, text: 'Free returns' },
  { icon: TagMoneyIcon, text: 'Guaranteed price matching' },
  { icon: Discount2Icon, text: '15% off after arrival' },
];

type MultiAnnouncementBannerProps = {
  hideContentUntilScrolledToTop: boolean;
  userContext: UserContext;
};

export const MultiAnnouncementBanner = ({
  hideContentUntilScrolledToTop,
  userContext,
}: MultiAnnouncementBannerProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false);
  const [perkIndex, setPerkIndex] = useState(0);
  const [banners, setBanners] = useState<WTopNavMessageView[]>([]);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [showBannerAnimation, setShowBannerAnimation] = useState(false);
  const hasMultipleBanners = useMemo(() => banners.length > 1, [banners.length]);

  const { legacy } = useBabyNavContext();

  useEffect(() => {
    const context = isLoggedIn(userContext)
      ? TopNavMessageContext.BABY_POST_AUTH
      : TopNavMessageContext.BABY_PRE_AUTH;

    getTopNavMessages(context)
      .then((response) => {
        if (response?.length > 0) {
          setBanners(response);
        }
      })
      .catch(() => undefined);
  }, [userContext]);

  const setNextBanner = useCallback(() => {
    // Start from beginning again once we've reached end of array
    if (bannerIndex === banners.length - 1) {
      setBannerIndex(0);
    } else {
      setBannerIndex(bannerIndex + 1);
    }
  }, [bannerIndex, banners.length]);

  const setPreviousBanner = useCallback(() => {
    // Start from beginning again once we've reached end of array
    if (bannerIndex === 0) {
      setBannerIndex(banners.length - 1);
    } else {
      setBannerIndex(bannerIndex - 1);
    }
  }, [bannerIndex, banners.length]);

  // Cycle through banners
  useEffect(() => {
    const interval = setInterval(() => {
      setNextBanner();
      setShowBannerAnimation(false);
    }, 5000);

    const animationInterval = setInterval(() => {
      setShowBannerAnimation(true);
    }, 4500);

    const clearIntervals = () => {
      clearInterval(interval);
      clearInterval(animationInterval);
    };

    if (isHovering || hideContentUntilScrolledToTop || !hasMultipleBanners) {
      clearIntervals();
    }

    return () => {
      clearIntervals();
    };
  }, [setNextBanner, isHovering, hideContentUntilScrolledToTop, hasMultipleBanners]);

  // Cycle through perks
  useEffect(() => {
    const interval = setInterval(() => {
      setPerkIndex((prevIndex) => (prevIndex + 1) % PERKS.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const showBanner = (showBannerFunction: () => void) => {
    setShowBannerAnimation(true);
    setTimeout(() => {
      showBannerFunction();
      setShowBannerAnimation(false);
    }, 500);
  };

  const NextButton = () => {
    return (
      <ButtonV3
        className={cx(styles.arrowButton, { [styles.show]: isHovering })}
        onClick={() => showBanner(setNextBanner)}
        title="Next announcement"
        variant="transparent"
      >
        <ChevronRightIcon className={styles.tanColor} width={20} height={20} showTitle={false} />
      </ButtonV3>
    );
  };
  const PreviousButton = () => {
    return (
      <ButtonV3
        className={cx(styles.arrowButton, { [styles.show]: isHovering })}
        onClick={() => showBanner(setPreviousBanner)}
        title="Previous announcement"
        variant="transparent"
      >
        <ChevronLeftIcon className={styles.tanColor} width={20} height={20} showTitle={false} />
      </ButtonV3>
    );
  };

  if (!legacy) {
    return (
      <div
        className={cx(styles.multiAnnouncementBanner, {
          [styles.hideContentOnScroll]: hideContentUntilScrolledToTop,
        })}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <div className={styles.links}>
          <LinkV2
            className={styles.tanColor}
            href={getZolaHref(featureFlags.getEnvironment())}
            noUnderline
            sizes="extraSmall"
            subtle
            target="_blank"
          >
            Zola Weddings
          </LinkV2>
          <LinkV2 className={styles.tanColor} href="/" sizes="extraSmall" subtle>
            Zola Baby
          </LinkV2>
        </div>
        <div className={styles.banner}>
          {hasMultipleBanners && <PreviousButton />}
          <Banner
            banner={banners.length > 0 ? banners[bannerIndex] : undefined}
            showBannerAnimation={showBannerAnimation}
          />
          {hasMultipleBanners && <NextButton />}
        </div>
        <div className={styles.perks}>
          {PERKS.map((perk, index) => (
            <div
              className={cx(styles.perk, { [styles.show]: perkIndex === index })}
              key={perk.text}
            >
              {perk.icon && <perk.icon title="" />}
              <P.BodySmall className={styles.tanColor}>{perk.text}</P.BodySmall>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.oldBanner, {
        [styles.hideContentOnScroll]: hideContentUntilScrolledToTop,
      })}
    >
      <P.BodySmall>You’re on Zola’s baby registry site. Looking for Zola Weddings?</P.BodySmall>
      <LinkV2 href={getZolaHref(featureFlags.getEnvironment())} sizes="smaller">
        Go to Zola Weddings
      </LinkV2>
    </div>
  );
};
